import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    if ('chart_pie_series' in this.element.dataset && JSON.parse(this.element.dataset.chart_pie_series).length > 0) {
      const data = JSON.parse(this.element.dataset.chart_pie_series);
      this.drawPieChart("#" + this.element.id, data);
    }

    if ('stacked_chart' in this.element.dataset) {
      const data = JSON.parse(this.element.dataset.stacked_chart);
      this.drawStackedChart("#" + this.element.id, data);
    }
  }

  drawPieChart(id, data) {
    $.plot(id, data, {
      series: {
        pie: {
          innerRadius: 0.5,
          show: true
        }
      },
      grid: {
        hoverable: true
      },
      color: null,
      tooltip: true,
      tooltipOpts: {
        content: '%p.0%, %s', // show percentages, rounding to 2 decimal places
        shifts: {
          x: 20,
          y: 0
        },
        defaultTheme: true
      }
    });
  }

  drawStackedChart(id, data) {
    new Chartist.Bar(id, data, {
      stackBars: true,
      axisY: {
        onlyInteger: true
      }
    }).on('draw', function(data) {
      if(data.type === 'bar') {
        data.element.attr({
          style: 'stroke-width: 30px'
        });
      }
    });
  }
}
