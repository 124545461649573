import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'icon']

  connect() {
    this.hidden = this.inputTarget.type === 'password'
  }

  toggle() {
    this.inputTarget.type = this.hidden ? 'text' : 'password'
    this.iconTarget.innerHTML = `<i class='fas ${this.hidden ? "fa-eye-slash" : "fa-eye"}'></i>`
    this.hidden = !this.hidden
  }
}
