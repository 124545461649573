import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = ['btn', 'advanceSearchSummary', 'advancedSearchForm', 'advanceSearchModal', 'template', 'templateContainer']

  connect() {
    this._templateContent = this.templateTarget.innerHTML
    this._itemIndex = 0

    if (this.templateContainerTarget.children.length === 0) {
      this.resetTemplates()
    }

    this.advancedSearchFormTarget.addEventListener('submit', this.submitAdvanceSearch.bind(this))
  }

  submitAdvanceSearch(event) {
    this.advanceSearchSummaryTarget.innerHTML = '<p>Ai cautat după: </p>'

    for (const child of this.templateContainerTarget.children) {
      let field = child.querySelector('[data-field=field]').value
      let value = child.querySelector('[data-field=value]').value

      if(value.length > 0) {
        this.advanceSearchSummaryTarget.innerHTML += `<p class='mb-0'>${field}: <i>${value}</i></p>`
      }
    }

    bootstrap.Modal.getInstance(this.advanceSearchModalTarget).hide()
  }

  resetTemplates() {
    this.templateContainerTarget.innerHTML = ''
    this.addItem()
    this.advanceSearchSummaryTarget.innerHTML = ''
  }

  addItem(event) {
    if(event) {
      event.preventDefault()
    }

    let content = this._templateContent.replaceAll('T_INDEX', this._itemIndex.toString())
    this.templateContainerTarget.insertAdjacentHTML('beforeend', content)
    this._itemIndex++
  }

  deleteItem(event) {
    event.preventDefault()
    let index = event.currentTarget.dataset.index
    document.querySelector(`#advancedCriteria_${index}`).remove()
  }
}
