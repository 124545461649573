import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sportAll", "sportPerfomance"];

  toggleSelects(e) {
    const selectedTarget = e.target;

    if (selectedTarget === this.sportAllTarget) {
      this.sportPerfomanceTarget.disabled = selectedTarget.value !== '';
    } else if (selectedTarget === this.sportPerfomanceTarget) {
      this.sportAllTarget.disabled = selectedTarget.value !== '';
    }
  }
}
