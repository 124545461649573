import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'checkbox']

  connect() {
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState() {
    console.log('updateSubmitButtonState')
    this.submitButtonTarget.disabled = !this.checkboxTarget.checked
  }

  onCheckboxChange(e) {
    this.updateSubmitButtonState()
  }
}
