import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $(this.element).observe(this.trigger)
  }

  trigger() {
    this.closest("form").requestSubmit()
  }
}
