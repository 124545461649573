import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["status"];

  toggle() {
    let checkbox = this.element.querySelector('input[type="checkbox"]');
    let status = this.statusTarget;

    checkbox.addEventListener('change', () => {
      status.textContent = checkbox.checked ? "DA" : "NU";
    });
  }
}
