import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template", "option_fields"]

  add(event) {
    event.preventDefault()  
    let current_index = this.add_itemTarget.parentElement.querySelectorAll('.crud-item').length + 1
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_INDEX/g, current_index)
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove(event) {
    event.preventDefault()
    event.target.closest(".crud-item").remove()
  }

  enable(event) {
    if(event.target.value == 'options') {
      this.option_fieldsTarget.classList.remove('d-none')
    } else {
      this.option_fieldsTarget.classList.add('d-none')
    }
  }
}
