import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', this.submitOrder)
  }

  submitOrder() {
    window.location = this.dataset.href
  }
}
  