import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    changeFieldsUrl: String,
  }

  changeFormTemplate(event) {
    get(`${this.changeFieldsUrlValue}`, {
      responseKind: "turbo-stream",
      query: {
        template: event.target.value
      }
    })
  }
}
