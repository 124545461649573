import { Controller } from "stimulus"
import { Sortable } from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    let id = event.item.dataset['id']
    let url = this.data.get('url').replace(':id', id)

    patch(url, {
      body: { position: event.newIndex + 1 },
      contentType: "application/json"
    });
  }
}
