import { Controller } from "stimulus";
import GLightbox from 'lib/glightbox/glightbox.min';

  export default class extends Controller {
    connect() {
      GLightbox({
        touchNavigation: true,
      });
    }
  }
