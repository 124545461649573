import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['dropdownItem', 'dropdownButton', 'intervalSpecificCheckbox', 'startDateInput', 'endDateInput', 'dateFieldsContainer'];

  connect() {
    this.dropdownItemTargets.forEach(item => {
      item.addEventListener("click", (event) => {
        this.handleDropdownItemClick(event);
      });
    });
  
    this.element.addEventListener("change", () => {
      this.toggleDateFields();
    });
  }

  toggleDateFields() {
    const isIntervalSpecific = this.intervalSpecificCheckboxTarget.checked;
  
    if (isIntervalSpecific) {
      this.dateFieldsContainerTarget.classList.remove('date-fields');
    } else {
      this.dateFieldsContainerTarget.classList.add('date-fields');
    }
  }
  
  handleDropdownItemClick(event) {
    event.stopPropagation();
    const selectedTimeRange = event.currentTarget.dataset.timeRange;
  
    if (selectedTimeRange === "all") {
      this.showAllResults();
    } else if (selectedTimeRange === "today" || selectedTimeRange === "yesterday") {
      this.setRelativeDate(selectedTimeRange);
    } else if (selectedTimeRange === "last_week") {
      this.setLastWeekDate();
    } else if (selectedTimeRange === "last_month") {
      this.setLastMonthDate();
    } else if (selectedTimeRange === "interval_specific") {
      this.toggleDateFields();
    }
    this.dropdownButtonTarget.innerHTML = `<i class="fa fa-calendar calendar-icon"></i> ${this.getButtonText(selectedTimeRange)} <b class="caret"></b>`;
  }

  showAllResults() {
    this.updateDateInputs('', '');
  }

  setRelativeDate(relativeType) {
    const today = new Date();
    let relativeDate;

    if (relativeType === "today") {
      relativeDate = today;
    } else if (relativeType === "yesterday") {
      relativeDate = new Date(today);
      relativeDate.setDate(today.getDate() - 1);
    }

    const startDate = relativeDate.toISOString().slice(0, 10);
    this.updateDateInputs(startDate, startDate);
  }

  setLastWeekDate() {
    const today = new Date();
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(today.getDate() - 7);

    const lastWeekEnd = new Date(today);
    lastWeekEnd.setDate(today.getDate() - 1);
  
    const startDate = lastWeekStart.toISOString().slice(0, 10);
    const endDate = lastWeekEnd.toISOString().slice(0, 10);
  
    this.updateDateInputs(startDate, endDate);
  }

  setLastMonthDate() {
    const today = new Date();
    const lastMonthStart = new Date(today);
    lastMonthStart.setMonth(today.getMonth() - 1);

    const startDate = lastMonthStart.toISOString().slice(0, 10);
    const endDate = today.toISOString().slice(0, 10);

    this.updateDateInputs(startDate, endDate);
  }

  updateDateInputs(startDate, endDate) {
    this.startDateInputTarget.value = startDate;
    this.endDateInputTarget.value = endDate;
  }

  getButtonText(selectedTimeRange) {
    switch (selectedTimeRange) {
      case "today":
        return "Azi";
      case "yesterday":
        return "Ieri";
      case "last_week":
        return "Ultima săptămână";
      case "last_month":
        return "Ultima lună";
      case "interval_specific":
        return "Interval Specific";
      default:
        return "Toate rezultatele";
    }
  }
}
