import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["sourceField", "ticketNoField", "sourceUrlField"]

  connect() {
    this.changeSource();
  }

  changeSource() {
    const currentSourceValue = this.sourceFieldTargets.find(el => el.checked).value
    if(currentSourceValue == 'city_app') {
      this.ticketNoFieldTarget.classList.remove('d-none')
      this.sourceUrlFieldTarget.classList.remove('d-none')
    } else {
      this.ticketNoFieldTarget.classList.add('d-none')
      this.sourceUrlFieldTarget.classList.add('d-none')
    }
  }
}
