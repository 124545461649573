import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).on('change', this.trigger)
  }

  trigger() {
    this.closest("form").requestSubmit()
  }
}
