import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "addressField", 
    "map",
    'fieldsContainer',
    "latitude", 
    "longitude", 
    "mapContainer",
    "street",
    "streetNumber",
    'postalCode',
    'country',
    'county',
    'city'
  ]

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }

  initializeMap() {
    this.map()
    this.marker()
    this.autocomplete()
  }

  map() {
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        zoom: 17,
        center: new google.maps.LatLng(
          this.latitudeTarget.value,
          this.longitudeTarget.value
        ),
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        navigationControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      })
    }
    return this._map
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0),
        draggable: true
      })
      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
    }
    return this._marker
  }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.addressFieldTarget)
      this._autocomplete.bindTo('bounds', this.map())
      this._autocomplete.setFields(['address_components', 'geometry', 'name'])
      this._autocomplete.addListener('place_changed', (e) => {
        this.locationChanged(this._autocomplete.getPlace().geometry.location)
        this.fillInAddress(this._autocomplete.getPlace())
      })
      google.maps.event.addListener(this._marker, "dragend", (e) => {
        this.locationChanged(e.latLng)
      })
    }
    return this._autocomplete
  }

  locationChanged(latLng) {
    this.map().setCenter(latLng)
    this.marker().setPosition(latLng)
    this.marker().setVisible(true)

    this.latitudeTarget.value = latLng.lat()
    this.longitudeTarget.value = latLng.lng()

    if(this.mapContainerTarget.classList.contains('d-none')) {
      this.mapContainerTarget.classList.remove('d-none')
    }

    if(this.fieldsContainerTarget.classList.contains('d-none')) {
      this.fieldsContainerTarget.classList.remove('d-none')
    }
  }

  fillInAddress(place) {
    // Get the place details from the autocomplete object.
    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
  
      switch (componentType) {
        case "street_number": {
          this.streetNumberTarget.value = component.long_name;
          break;
        }
  
        case "route": {
          this.streetTarget.value = component.short_name;
          break;
        }
  
        case "postal_code": {
          this.postalCodeTarget.value = `${component.long_name}${this.postalCodeTarget.value}`;
          break;
        }
  
        case "postal_code_suffix": {
          this.postalCodeTarget.value = `${this.postalCodeTarget.value}-${component.long_name}`;
          break;
        }
        case "locality":
          this.cityTarget.value = component.long_name;
          break;
        case "administrative_area_level_1": {
          this.countyTarget.value = component.long_name;
          break;
        }
        case "country":
          this.countryTarget.value = component.long_name;
          break;
      }
    }
  }

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }
}
